import React from "react";
import { useTranslation } from "react-i18next";
import logoDiscord from "../assets/icons/logo-discord.svg";
import logoZoom from "../assets/icons/logo-zoom.svg";

const DeseasSaberMas = () => {
  const { t } = useTranslation();

  return (
    <div className="component-deseasSaberMas">
      <div className="container-title">
        <h1 className="title">
          {t("Deseas saber más sobre el mundo de")}
          <span className="title-blue"> IA Money</span>
        </h1>
      </div>
      <div className="container-cards">
        <a href="https://www.discord.com/" target="_blank" rel="noreferrer">
          <div className="card-discord card-global">
            <div className="container-img">
              <img src={logoDiscord} alt="" />
            </div>
            <p>{t("unete a nuestro Discord")}</p>
          </div>
        </a>
        <a href="https://www.zoom.com/" target="_blank" rel="noreferrer">
          <div className="card-zoom card-global">
            <div className="container-img">
              <img src={logoZoom} alt="" />
            </div>
            <p>{t("Reunámonos en Zoom")}</p>
          </div>
        </a>
      </div>
    </div>
  );
};

export default DeseasSaberMas;
