import React from "react";
import tecnologyImg from "../assets/imgs/tecnologia.png";
import protocoloImg from "../assets/imgs/protocolo.png";
import contratosImg from "../assets/imgs/contratosI.png";
import gananciasImg from "../assets/imgs/ganancias.png";
import plusImg from "../assets/imgs/plus_sign.png";
import { useTranslation } from "react-i18next";

const QueHacemos = () => {
  const { t } = useTranslation("");

  const cardList = [
    {
      id: 1,
      image: tecnologyImg,
      text: t("TECNOLOGÍA BLOCKCHAIN"),
    },
    {
      id: 2,
      image: protocoloImg,
      text: t("PROTOCOLO DE ALTA FRECUENCIA"),
    },
    {
      id: 3,
      image: contratosImg,
      text: t("CONTRATOS INTELIGENTES"),
    },
    {
      id: 4,
      image: gananciasImg,
      text: t("GANANCIAS DIARIAS"),
    },
  ];

  return (
    <div className="component-queHacemos">
      <div className="container-text">
        <h2>{t("QUÉ HACEMOS")}</h2>
        <h1>{t("Creamos ROI para ti")}</h1>
        <p>
          {t("Te ofrecemos soluciones de crecimiento financiero")}{" "}
          <span>{t("Somos expertos en finanzas y estrategias")}</span>
        </p>
      </div>
      <div className="container-map-card">
        {cardList.map((card) => {
          return (
            <div className="container-card" key={card.id}>
              <div className="card-info">
                <div className="container-img">
                  <img className="imgs-card" src={card.image} alt={card.text} />
                </div>
                <p className="parrafo-card">{card.text}</p>
              </div>
              <div
                className={`${card.id === 4 ? "d-none" : "container-img-plus"}`}
              >
                <img className="img-plus" src={plusImg} alt="" />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default QueHacemos;
