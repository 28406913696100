import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Modal from "react-modal";
import { NumberToMoney } from "../utilities/others";
import moment from "moment";
import { Link } from "react-router-dom";

Modal.setAppElement("#root");

const EligePlan = () => {
  const { t } = useTranslation("");

  const [planes, setPlanes] = useState([]);
  const [modalIsOpenPlan, setModalIsOpenPlan] = useState(false);
  const [inputAmountToStaking, setInputAmountToStaking] = useState(0);
  const [erroneousAmountMessage, setErroneousAmountMessage] = useState("");
  const [getDatosModalPlan, setGetDatosModalPlan] = useState({
    title: "",
    Amount: {
      title: "",
      value: "",
    },
    daily: {
      title: "",
      value: "",
    },
    monthly: {
      title: "",
      value: "",
    },
    roi: {
      title: "",
      value: "",
    },
    endDay: {
      title: "",
      value: "",
    },
    bloque: {
      title: "",
      value: "",
    },
  });

  const secondDaily = 60 * 60 * 24; // 86.400 segundos diarios
  const secondBlock = 30; // bloques por segundo
  const periodInDays = 360;
  const periodInMonts = 30;
  const handlerModalClosePlan = () => setModalIsOpenPlan(false);
  const handlerModalOpenPlan = () => {
    setModalIsOpenPlan(true);
  };

  function selectPlan(plan) {
    setPlanes(plan.plan);
    plan.inversionI = Number(plan.inversionI);
    plan.inversionF = Number(plan.inversionF);
    plan.roi = Number(plan.roi);

    const calculate = {
      ...plan,
      title: plan.plan,
      daily: {
        title: t("Ganancia Diaria"),
        value: NumberToMoney({
          number: (plan.inversionI * plan.roi) / 100 / periodInDays,
          firstSign: "$",
        }),
      },
      monthly: {
        title: t("Beneficio Mensual"),
        value: NumberToMoney({
          number: (plan.inversionI * plan.roi) / 100 / periodInMonts,
          firstSign: "$",
        }),
      },
      roi: {
        title: t("ROI"),
        value: NumberToMoney({
          number: (plan.inversionI * plan.roi) / 100,
          firstSign: "$",
        }),
      },
      endDay: {
        title: t("Contrato de fin de día"),
        value: moment().add(360, "days").format("YYYY-MM-DD"),
      },
      bloque: {
        title: t("Beneficio por Bloque"),
        value: NumberToMoney({
          number:
            (plan.inversionI * plan.roi) /
            100 /
            periodInDays /
            (secondDaily / secondBlock),
          firstSign: "$",
          numberOfDecimals: 10,
        }),
      },
      roiTotal: plan.roi,
    };

    setInputAmountToStaking(plan.inversionI);
    setGetDatosModalPlan(calculate);
  }

  function handleCalculateAmountOfPlan(targetElement) {
    setInputAmountToStaking(targetElement.value);
    setGetDatosModalPlan({
      ...getDatosModalPlan,
      daily: {
        title: t("Daily profit"),
        value: NumberToMoney({
          number:
            (targetElement.value * getDatosModalPlan.roiTotal) /
            100 /
            periodInDays,
          firstSign: "$",
        }),
      },
      monthly: {
        title: t("Beneficio Mensual"),
        value: NumberToMoney({
          number:
            (targetElement.value * getDatosModalPlan.roiTotal) /
            100 /
            periodInMonts,
          firstSign: "$",
        }),
      },
      roi: {
        title: t("ROI"),
        value: NumberToMoney({
          number: (targetElement.value * getDatosModalPlan.roiTotal) / 100,
          firstSign: "$",
        }),
      },
      bloque: {
        title: t("Beneficio por Bloque"),
        value: NumberToMoney({
          number:
            (targetElement.value * getDatosModalPlan.roiTotal) /
            100 /
            periodInDays /
            (secondDaily / secondBlock),
          firstSign: "$",
          numberOfDecimals: 10,
        }),
      },
    });
  }

  const listPlans = [
    {
      plan: "1",
      roi: "48",
      retirosD: "0.13",
      inversionI: "250",
      inversionF: "499",
    },
    {
      plan: "2",
      roi: "60",
      retirosD: "0.16",
      inversionI: "500",
      inversionF: "999",
    },
    {
      plan: "3",
      roi: "72",
      retirosD: "0.2",
      inversionI: "1000",
      inversionF: "4999",
    },
    {
      plan: "4",
      roi: "84",
      retirosD: "0.23",
      inversionI: "5000",
      inversionF: "9999",
    },
    {
      plan: "5",
      roi: "96",
      retirosD: "0.26",
      inversionI: "10000",
      inversionF: "24999",
    },
  ];

  return (
    <div className="component-eligePlan" id="#planes">
      <div className="container-title">
        <h1 className="title">
          {t("Elige el plan de inversiones que mejor se adapte a ti")}
        </h1>
      </div>
      <div className="container-plans row w-100 mx-0">
        {listPlans.map((plan) => {
          return (
            <div
              className={`container-plan-info col-6 col-md-3 ${
                planes === plan.plan ? "--planSelected" : ""
              }`}
              key={plan.plan}
              onClick={() => selectPlan(plan)}
            >
              <h2 className="plan">
                {t("Plan")} {plan.plan}
              </h2>
              <div className="container-roi">
                <p className="roi">
                  {t("ROI de")} {plan.roi}%
                </p>
                <p className="retirosD">
                  {plan.retirosD}% {t("de retiros diarios")}
                </p>
              </div>

              <div className="container-inversion">
                <p className="text-inversion">{t("Inversión de")}</p>
                <p className="monto">
                  ${plan.inversionI} {t("a")} ${plan.inversionF}
                </p>
              </div>
            </div>
          );
        })}
      </div>
      <div className="container-buttons">
        <button className="button-blue" onClick={handlerModalOpenPlan}>
          {t("CALCULAR MI INVERSIÓN")}
        </button>
      </div>
      <Modal
        isOpen={modalIsOpenPlan}
        onRequestClose={handlerModalClosePlan}
        contentLabel="Example Modal"
        className="container-calcular"
      >
        <div className="modal-calcular-info">
          <h6 className="title-modal-stake text-center">
            {t("Calculadora de inversión")}
          </h6>

          <div className="row containerAux">
            <div className="col-12 col-md-6 d-flex flex-column">
              <label className="form-label">Staking</label>
              <input
                className="input-type1"
                type="number"
                value={inputAmountToStaking}
                onChange={(event) => handleCalculateAmountOfPlan(event.target)}
                max={getDatosModalPlan.inversionF}
                min={getDatosModalPlan.inversionI}
              />
            </div>
            <div className="col-12 col-md-6 d-flex flex-column">
              <label className="form-label">
                {getDatosModalPlan.endDay.title}
                {/* {t("Contrato de fin de día")} */}
              </label>
              <input
                className="input-type1"
                type="date"
                value={getDatosModalPlan.endDay.value}
                readOnly
              />
            </div>
          </div>
          <div className="row containerAux">
            <div className="col-12 col-md-6 d-flex flex-column">
              <label className="form-label">
                {getDatosModalPlan.daily.title}
                {/* {t("Ganancia Diaria")} */}
              </label>
              <input
                className="input-type1"
                type="text"
                value={getDatosModalPlan.daily.value}
                readOnly
              />
            </div>
            <div className="col-12 col-md-6 d-flex flex-column">
              <label className="form-label">
                {getDatosModalPlan.bloque.title}
                {/* {t("Beneficio por Bloque")} */}
              </label>
              <input
                className="input-type1"
                type="text"
                value={getDatosModalPlan.bloque.value}
                readOnly
              />
            </div>
          </div>
          <div className="row containerAux">
            <div className="col-12 col-md-6 d-flex flex-column">
              <label className="form-label">
                {getDatosModalPlan.monthly.title}
                {/* {t("Beneficio Mensual")} */}
              </label>
              <input
                className="input-type1"
                type="text"
                value={getDatosModalPlan.monthly.value}
                readOnly
              />
            </div>
            <div className="col-12 col-md-6 d-flex flex-column">
              <label className="form-label">
                {getDatosModalPlan.roi.title}
                {/* {t("ROI")} */}
              </label>
              <input
                className="input-type1"
                type="text"
                value={`${getDatosModalPlan.roi.value}`}
                readOnly
              />
            </div>
          </div>

          <div className="botones-modal-calcular">
            <Link to={"https://dapp.iamoney.finance/"} target="_blank">
              <button className="button-blue" onClick={handlerModalClosePlan}>
                {t("Me gustaría invertir")}
              </button>
            </Link>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default EligePlan;
