import React from "react";
import Logo from "../assets/imgs/IA_LOGO- HORIZONTAL-Whitee.png";
import { Link, useLocation } from "react-router-dom";
import ContainerLanguage from "./ContainerLanguage";
import { useTranslation } from "react-i18next";
import { Link as LinkScroll } from "react-scroll";

const Navbar = () => {
  const { t } = useTranslation("");
  const location = useLocation();

  return (
    <div
      className={`${
        location.pathname === "/terminosYCondiciones"
          ? "d-none"
          : "component-navbar"
      }`}
    >
      <div className="container-logo">
        <Link to={"/"}>
          <img className="img-logo" src={Logo} alt="Logo" />
        </Link>
      </div>
      <div className="container-list-opt">
        {location.pathname === "/preguntasFrecuentes" ? (
          <ul className="container-list">
            <Link>
              <p className="unete">{t("Únete")}</p>
            </Link>
          </ul>
        ) : (
          <ul className="container-list">
            <LinkScroll
              to={"#planes"}
              spy={true}
              smooth={true}
              offset={-92}
              duration={500}
            >
              <p className="planes">{t("planes")}</p>
            </LinkScroll>
            <LinkScroll
              to={"#contacto"}
              spy={true}
              smooth={true}
              offset={-5}
              duration={500}
            >
              <p className="contacto">{t("Contacto")}</p>
            </LinkScroll>
            <a
              href="https://dapp.iamoney.finance/"
              target="_blank"
              rel="noreferrer"
            >
              <p className="unete">{t("Únete")}</p>
            </a>
          </ul>
        )}

        <div className="container-languaje">
          <ContainerLanguage />
        </div>
      </div>
    </div>
  );
};

export default Navbar;
