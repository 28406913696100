import React, { useEffect, useState } from "react";
import img1 from "../assets/imgs/carrusel1.jpg";
import img2 from "../assets/imgs/carrusel2.jpg";
import img3 from "../assets/imgs/carrusel3.jpg";
import img4 from "../assets/imgs/carrusel4.jpg";
import BarraCarousel from "./BarraCarousel";
import img1Movil from "../assets/imgs/carruselmovil1.jpg";
import img2Movil from "../assets/imgs/carruselmovil2.jpg";
import img3Movil from "../assets/imgs/carruselmovil3.jpg";
import img4Movil from "../assets/imgs/carruselmovil4.jpg";
import { useTranslation } from "react-i18next";

const Carousel = () => {
  const { t } = useTranslation("");
  const [selectImg, setSelectImg] = useState(1);

  const listBanner = [
    {
      id: 1,
      img: img1,
      alt: "",
      titulo: t("GENERA INTERÉS CON TU CAPITAL"),
      sutTitulo: t("Haz que tu dinero se multiplique solo"),
    },
    {
      id: 2,
      img: img2,
      alt: "",
      titulo: t("TODOS PUEDEN UNIRSE A IA MONEY"),
      sutTitulo: t("Nuestro sistema es amigable y fácil de usar"),
    },
    {
      id: 3,
      img: img3,
      alt: "",
      titulo: t("AYUDA A TU FAMILIA Y AMIGOS"),
      sutTitulo: t("Nuestro sistema de referidos hace que todos ganen juntos"),
    },
    {
      id: 4,
      img: img4,
      alt: "",
      titulo: t("NO TE PREOCUPES POR LA DEVALUACIÓN"),
      sutTitulo: t("Te damos las herramientas para proteger tu capital"),
    },
  ];

  const listBannerMovil = [
    {
      id: 1,
      img: img1Movil,
      alt: "",
      titulo: t("GENERA INTERÉS CON TU CAPITAL"),
      sutTitulo: t("Haz que tu dinero se multiplique solo"),
    },
    {
      id: 2,
      img: img2Movil,
      alt: "",
      titulo: t("TODOS PUEDEN UNIRSE A IA MONEY"),
      sutTitulo: t("Nuestro sistema es amigable y fácil de usar"),
    },
    {
      id: 3,
      img: img3Movil,
      alt: "",
      titulo: t("AYUDA A TU FAMILIA Y AMIGOS"),
      sutTitulo: t("Nuestro sistema de referidos hace que todos ganen juntos"),
    },
    {
      id: 4,
      img: img4Movil,
      alt: "",
      titulo: t("NO TE PREOCUPES POR LA DEVALUACIÓN"),
      sutTitulo: t("Te damos las herramientas para proteger tu capital"),
    },
  ];

  function toogleImgBanner(caso, id) {
    console.log(caso);
    if (caso === "suma") {
      if (selectImg >= listBanner.length) {
        setSelectImg(1);
        return;
      }
      setSelectImg(selectImg + 1);
    }

    if (caso === "resta") {
      if (selectImg <= 1) {
        setSelectImg(listBanner.length);
        return;
      }
      setSelectImg(selectImg - 1);
    }
    if (caso === "barra" && id) {
      setSelectImg(id);
    }
  }

  useEffect(() => {
    // console.log("iniciando");
    const keyTime = setInterval(() => toogleImgBanner("suma"), 1000 * 5);
    return () => {
      // console.log("finalizando", keyTime);
      clearTimeout(keyTime);
    };
  }, [toogleImgBanner]);

  return (
    <div className="container-banner-interno1">
      <BarraCarousel
        list={listBanner}
        selectImg={selectImg}
        toogleImgBanner={toogleImgBanner}
        styleCss="containerBarraFlecha"
      />
      <div className="container-banner">
        <a href="" target="_blank" rel="noreferrer">
          <button className="btn-banner-comienza button-blue">
            {t("Empieza a ganar ahora")}
          </button>
        </a>

        <div className="container-notifications-maintenance">
          <p>{t("Dapp y servicio estará fuera de línea por mantenimiento")}</p>
        </div>

        {listBanner.map((img) => {
          return (
            <div className="container-banner-info" key={img.id}>
              <div
                className={`container-content ${
                  selectImg === img.id ? "block" : "hidden"
                }`}
              >
                <h1 className={`h1Global`}>{img.titulo}</h1>
                <p className={`pGlobal`}>{img.sutTitulo}</p>
              </div>
              <img
                src={img.img}
                alt=""
                className={`imagenes ${
                  selectImg === img.id ? "block" : "hidden"
                }`}
              />
            </div>
          );
        })}
      </div>

      <div className="container-banner-movil">
        <a href="" target="_blank" rel="noreferrer">
          <button className="btn-banner-comienza button-blue">
            {t("Empieza a ganar ahora")}
          </button>
        </a>

        <div className="container-notifications-maintenance">
          <p>{t("Dapp y servicio estará fuera de línea por mantenimiento")}</p>
        </div>

        {listBannerMovil.map((img) => {
          return (
            <div className="container-banner-info-movil" key={img.id}>
              <div
                className={`container-content-movil ${
                  selectImg === img.id ? "block" : "hidden"
                }`}
              >
                <h1 className={`h1Global`}>{img.titulo}</h1>
                <p className={`pGlobal`}>{img.sutTitulo}</p>
              </div>
              <img
                src={img.img}
                alt=""
                className={`imagenes ${
                  selectImg === img.id ? "block" : "hidden"
                }`}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Carousel;
