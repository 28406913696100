import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import logo from "../assets/imgs/IA_LOGO- HORIZONTAL-Whitee.png";
import iconZoom from "../assets/icons/logo-zoom-footer.svg";
import iconTwitter from "../assets/icons/logo-twitter-footer.svg";
import iconFacebook from "../assets/icons/logo-facebook-footer.svg";
import iconInstagram from "../assets/icons/logo-instagram-footer.svg";
import iconDiscord from "../assets/icons/logo-discord-footer.svg";

const Footer = () => {
  const { t } = useTranslation();
  const location = useLocation();

  const listRedes = [
    {
      name: "zoom",
      img: iconZoom,
      link: "https://www.zoom.com/",
    },
    {
      name: "twitter",
      img: iconTwitter,
      link: "https://www.twitter.com/",
    },
    {
      name: "facebook",
      img: iconFacebook,
      link: "https://www.facebook.com/",
    },
    {
      name: "instagram",
      img: iconInstagram,
      link: "https://www.instagram.com/",
    },
    {
      name: "discord",
      img: iconDiscord,
      link: "https://www.discord.com/",
    },
  ];

  return (
    <div
      className={`${
        location.pathname === "/terminosYCondiciones"
          ? "d-none"
          : "component-footer"
      }`}
    >
      <div className="container-up">
        <div className="container-1 row">
          <div className="container-contactanos col-12 col-lg-4">
            <h2>{t("Contáctanos")}</h2>
            <a href="mailto:info@iamoney.finance">info@iamoney.finance</a>
          </div>
          <div className="container-nosotros col-12 col-lg-4">
            <div className="container-centrado">
              <h2>{t("Nosotros")}</h2>
              <Link to={"/terminosYCondiciones"} target="_blank">
                {t("Términos y condiciones")}
              </Link>
            </div>
          </div>
          <div className="container-img-logo col-12 col-lg-4">
            <img className="img-logo" src={logo} alt="" />
          </div>
        </div>
        <div className="container-2 row">
          <div className="container-soporte col-12 col-lg-4">
            <h2>{t("Soporte")}</h2>
            <a href="mailto:support@iamoney.finance">support@iamoney.finance</a>
          </div>
          <div className="container-redes col-12 col-lg-4">
            <div className="container-center">
              {listRedes.map((redSocial, index) => {
                return (
                  <div className="container-img-redSocial" key={index}>
                    {/* <div className="container-centrado"> */}
                    <a href={redSocial.link} target="_blank" rel="noreferrer">
                      <img src={redSocial.img} alt={redSocial.name} />
                    </a>
                    {/* </div> */}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <div className="container-down">
        <p>© 2023. IA Money. {t("Todos los derechos reservados")}</p>
      </div>
    </div>
  );
};

export default Footer;
