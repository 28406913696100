import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import iconDown from "../assets/icons/icons8-down-50.png";

const Preguntas = () => {
  const { t } = useTranslation();

  const listQuestion = [
    {
      id: 1,
      question: t("¿Que es Blockchain?"),
      answer: t("answer1"),
    },
    {
      id: 2,
      question: t("¿Qué es PancakeSwap?"),
      answer: t("answer2"),
    },
    {
      id: 3,
      question: t("¿Qué es Web3?"),
      answer: t("answer3"),
    },
    {
      id: 4,
      question: t("¿Qué es un Token?"),
      answer: t("answer4"),
    },
    {
      id: 5,
      question: t("¿Qué es Metamask?"),
      answer: t("answer5"),
    },
    {
      id: 6,
      question: t("¿Qué es Binance Smart Chain (BSC)?"),
      answer: t("answer6"),
    },
  ];

  return (
    <div className="component-preguntas">
      <div className="container-preguntas-text">
        <h1>{t("Preguntas Frecuentes")}</h1>
      </div>
      <div className="container-list-question">
        <div className="accordion" id="accordionExample">
          {listQuestion.map((question, index) => {
            return (
              <div className="accordion-item" key={index}>
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed w-100 d-flex justify-content-between"
                    data-bs-toggle="collapse"
                    data-bs-target={`#collapse${question.id}`}
                    aria-expanded="true"
                    aria-controls={`collapse${question.id}`}
                  >
                    {question.question}
                    {/* <img src={iconDown} alt="" /> */}
                  </button>
                </h2>
                <div
                  id={`collapse${question.id}`}
                  className={`accordion-collapse collapse`}
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">{question.answer}</div>
                </div>
              </div>
            );
          })}
        </div>
        <div className="container-button">
          <Link to={"/preguntasFrecuentes"}>
            <button className="button-preguntas">
              {t("Ver más preguntas")}
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Preguntas;
