import React from "react";
import { useTranslation } from "react-i18next";

const TerminosCondiciones = () => {
  const { t } = useTranslation();

  return (
    <div className="page-terminosCondiciones">
      <h1>{t("Aviso Legal")}</h1>
      <div className="container-termino1 container-global">
        <h2>{t("TÉRMINOS Y CONDICIONES DE USO DE LA DAPP O SITIO WEB")}</h2>
        <p>{t("parrafo1")}</p>
        <p>{t("parrafo2")}</p>
        <p>{t("parrafo3B")}</p>
        <p>{t("parrafo4")}</p>
        <p>
          {t("parrafo5")}{" "}
          <span className="fw-bold text-decoration-underline">
            {t("parrafo5.1B")}
          </span>{" "}
          <span>{t("parrafo5.2")}</span>
        </p>
      </div>
      <div className="container-termino2 container-global">
        <h2>{t("Modificación de este Acuerdo")}</h2>
        <p>{t("parrafo6")}</p>
      </div>
      <div className="container-termino3 container-global">
        <h2>
          {t("Descripción de los servicios prestados a través de la DAPP")}
        </h2>
        <div className="container-Preg-resp">
          <p>{t("parrafo7P")}</p>
          <p>{t("parrafo7R")}</p>
        </div>
        <div className="container-Preg-resp">
          <p>{t("parrafo8P")}</p>
          <p>{t("parrafo8R")}</p>
        </div>
        <div className="container-Preg-resp">
          <p>{t("parrafo9P")}</p>
          <p>{t("parrafo9R")}</p>
        </div>
        <div className="container-Preg-resp">
          <p>{t("parrafo10P")}</p>
          <p>{t("parrafo10R")}</p>
        </div>
        <div className="container-Preg-resp">
          <p>{t("parrafo11P")}</p>
          <p>{t("parrafo11R")}</p>
        </div>
      </div>
      <div className="container-termino4 container-global">
        <h2>{t("Idoneidad")}</h2>
        <p>{t("parrafo12")}</p>
      </div>
      <div className="container-termino5 container-global">
        <h2>{t("Derechos de Propiedad Intelectual")}</h2>
        <p>{t("parrafo13")}</p>
      </div>
      <div className="container-termino6 container-global">
        <h2>{t("No custodia y No Deberes Fiduciarios")}</h2>
        <p>
          {t("parrafo14")}{" "}
          <span className="fw-bold text-decoration-underline fst-italic">
            {t("parrafo14.1B")}
          </span>
        </p>
        <p>{t("parrafo15")}</p>
      </div>
      <div className="container-termino7 container-global">
        <h2>{t("Cumplimiento y Obligaciones Fiscales")}</h2>
        <p>{t("parrafo16")}</p>
        <p>{t("parrafo17")}</p>
      </div>
      <div className="container-termino8 container-global">
        <h2>{t("Asunción del Riesgo")}</h2>
        <p>{t("parrafo18")}</p>
        <p>{t("parrafo19")}</p>
        <p>{t("parrafo20")}</p>
        <p>{t("parrafo21")}</p>
        <p>{t("parrafo22")}</p>
      </div>
      <div className="container-termino9 container-global">
        <h2>{t("Preguntas y Respuestas frente a los Riesgos")}</h2>
        <div className="container-Preg-resp">
          <p>{t("parrafo23P")}</p>
          <p>{t("parrafo23R")}</p>
        </div>
        <div className="container-Preg-resp">
          <p>{t("parrafo24P")}</p>
          <p>{t("parrafo24R")}</p>
        </div>
        <div className="container-Preg-resp">
          <p>{t("parrafo25P")}</p>
          <p>{t("parrafo25R")}</p>
        </div>
        <div className="container-Preg-resp">
          <p>{t("parrafo26P")}</p>
          <p>{t("parrafo26R")}</p>
        </div>
        <div className="container-Preg-resp">
          <p>{t("parrafo27P")}</p>
          <p>{t("parrafo27R")}</p>
        </div>
        <div className="container-Preg-resp">
          <p>{t("parrafo28P")}</p>
          <p>{t("parrafo28R")}</p>
        </div>
        <div className="container-Preg-resp">
          <p>{t("parrafo29P")}</p>
          <p>{t("parrafo29R")}</p>
        </div>
        <div className="container-Preg-resp">
          <p>{t("parrafo30P")}</p>
          <p>{t("parrafo30R")}</p>
        </div>
        <div className="container-Preg-resp">
          <p>{t("parrafo31P")}</p>
          <p>{t("parrafo31R")}</p>
        </div>
        <div className="container-Preg-resp">
          <p>{t("parrafo32P")}</p>
          <p>{t("parrafo32R")}</p>
        </div>
      </div>
      <div className="container-termino10 container-global">
        <h2>{t("Liberación de Reclamaciones")}</h2>
        <p>{t("parrafo33")}</p>
        <p>{t("parrafo34")}</p>
        <p>{t("parrafo35")}</p>
      </div>
      <div className="container-termino11 container-global">
        <h2>{t("Sin Garantías")}</h2>
        <p>{t("parrafo36")}</p>
      </div>
      <div className="container-termino12 container-global">
        <h2>{t("Exención de Demanda Colectiva y Juicio con Jurado")}</h2>
        <p>{t("parrafo37")}</p>
      </div>
    </div>
  );
};

export default TerminosCondiciones;
