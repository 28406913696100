import React from "react";
import imagenPig from "../assets/gif/Money.gif";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Inscribete = () => {
  const { t } = useTranslation();

  return (
    <div className="component-inscribete">
      <div className="container-border">
        <div className="container-img">
          <img className="imagen-pig" src={imagenPig} alt="" />
        </div>
        <div className="container-info">
          <h1 className="title">{t("Inscríbete y únete a mi equipo")}</h1>
          <Link>{t("Empieza a ganar ahora")}</Link>
        </div>
      </div>
    </div>
  );
};

export default Inscribete;
