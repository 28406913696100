import React from "react";
import imgDelfin from "../assets/gif/Coins.gif";
import { useTranslation } from "react-i18next";

const ComoFunciona = () => {
  const { t } = useTranslation("");

  return (
    <div className="component-comoFunciona">
      <div className="container-up">
        <h1 className="title">
          ¿{t("Como funciona")} <span className="title-blue">IA Money</span>?
        </h1>
        <p className="text">{t("Es muy simple")}</p>
      </div>
      <div className="container-down">
        <div className="list-options ">
          <div className="container-global">
            <div className="number">1</div>
            <div className="container-text">
              <p className="options">
                {t("Conecta tu wallet a la plataforma")}
              </p>
            </div>
          </div>
          <div className="container-global">
            <div className="number">2</div>
            <div className="container-text">
              <p className="options">{t("Elige tu plan de inversiones")}</p>
            </div>
          </div>
          <div className="container-global">
            <div className="number">3</div>
            <div className="container-text">
              <p className="options">{t("Retira tus ganancias a diario")}</p>
            </div>
          </div>
          <div className="container-global">
            <div className="number">4</div>
            <div className="container-text">
              <p className="options">{t("Refiere a tus amistades")}</p>
            </div>
          </div>
        </div>
        <div className="container-img">
          <img className="imgDelfin" src={imgDelfin} alt="Delfin" />
        </div>
      </div>
    </div>
  );
};

export default ComoFunciona;
