import React from "react";
import { useTranslation } from "react-i18next";

const PreguntasFrecuentes = () => {
  const { t } = useTranslation();

  const listQuestionMovil = [
    {
      id: 18,
      question: t("¿Que es Blockchain?"),
      answer: t("answer1"),
    },
    {
      id: 19,
      question: t("¿Qué es PancakeSwap?"),
      answer: t("answer2"),
    },
    {
      id: 20,
      question: t("¿Qué es Web3?"),
      answer: t("answer3"),
    },
    {
      id: 21,
      question: t("¿Qué es un Token?"),
      answer: t("answer4"),
    },
    {
      id: 22,
      question: t("¿Qué es Metamask?"),
      answer: t("answer5"),
    },
    {
      id: 23,
      question: t("¿Qué es Binance Smart Chain (BSC)?"),
      answer: t("answer6"),
    },
    {
      id: 1,
      question: t("¿Qué es y cómo funciona IA Money?"),
      answer: t("answer7"),
    },
    {
      id: 2,
      question: t("¿Qué es un Claim Smart Contract?"),
      answer: t("answer8"),
    },
    {
      id: 3,
      question: t("¿Qué es un Treasury Smart Contract?"),
      answer: t("answer9"),
    },
    {
      id: 4,
      question: t("¿Qué es el Protocolo Tangle?"),
      answer: t("answer10"),
    },
    {
      id: 5,
      question: t("¿Qué es un DEX?"),
      answer: t("answer11"),
    },
    {
      id: 6,
      question: t("¿Qué es un PoH?"),
      answer: t("answer12"),
    },
    {
      id: 7,
      question: t("¿Cuáles son los riesgos de invertir en IA Money?"),
      answer: t("answer13"),
    },
    {
      id: 8,
      question: t(
        "¿Cuáles son los riegos de que el protocolo deje de funcionar?"
      ),
      answer: t("answer14"),
    },
    {
      id: 9,
      question: t("¿Qué pasa con el dinero si PancakeSwap deja de funcionar?"),
      answer: t("answer15"),
    },
    {
      id: 10,
      question: t("¿Qué pasa con el dinero si la Dapp deja de funcionar?"),
      answer: t("answer16"),
    },
    {
      id: 11,
      question: t(
        "¿Qué pasa con el dinero si el Protocolo dejara de funcionar?"
      ),
      answer: t("answer17"),
    },
    {
      id: 12,
      question: t("Probabilidades de que las personas pierdan sus recursos"),
      answer: t("answer18"),
    },
    {
      id: 13,
      question: t(
        "Probabilidad de que las personas no reciban el porcentaje prometido"
      ),
      answer: t("answer19"),
    },
    {
      id: 14,
      question: t("¿Cómo se mitiga el riesgo con el protocolo?"),
      answer: t("answer20"),
    },
    {
      id: 15,
      question: t("¿Por qué utilizamos Smart Contract?"),
      answer: t("answer21"),
    },
    {
      id: 16,
      question: t("¿Por qué utilizamos Blockchain y por qué la de BNB Chain?"),
      answer: t("answer22"),
    },
    {
      id: 17,
      question: t(
        "¿Qué pasaría si Binance deja de funcionar, esto afectaría la red de BNB?"
      ),
      answer: t("answer23"),
    },
  ];

  const listQuestion = [
    {
      id: 1,
      question: t("¿Qué es y cómo funciona IA Money?"),
      answer: t("answer7"),
    },
    {
      id: 2,
      question: t("¿Qué es un Claim Smart Contract?"),
      answer: t("answer8"),
    },
    {
      id: 3,
      question: t("¿Qué es un Treasury Smart Contract?"),
      answer: t("answer9"),
    },
    {
      id: 4,
      question: t("¿Qué es el Protocolo Tangle?"),
      answer: t("answer10"),
    },
    {
      id: 5,
      question: t("¿Qué es un DEX?"),
      answer: t("answer11"),
    },
    {
      id: 6,
      question: t("¿Qué es un PoH?"),
      answer: t("answer12"),
    },
    {
      id: 7,
      question: t("¿Cuáles son los riesgos de invertir en IA Money?"),
      answer: t("answer13"),
    },
    {
      id: 8,
      question: t(
        "¿Cuáles son los riegos de que el protocolo deje de funcionar?"
      ),
      answer: t("answer14"),
    },
    {
      id: 9,
      question: t("¿Qué pasa con el dinero si PancakeSwap deja de funcionar?"),
      answer: t("answer15"),
    },
    {
      id: 10,
      question: t("¿Qué pasa con el dinero si la Dapp deja de funcionar?"),
      answer: t("answer16"),
    },
    {
      id: 11,
      question: t(
        "¿Qué pasa con el dinero si el Protocolo dejara de funcionar?"
      ),
      answer: t("answer17"),
    },
    {
      id: 12,
      question: t("Probabilidades de que las personas pierdan sus recursos"),
      answer: t("answer18"),
    },
    {
      id: 13,
      question: t(
        "Probabilidad de que las personas no reciban el porcentaje prometido"
      ),
      answer: t("answer19"),
    },
    {
      id: 14,
      question: t("¿Cómo se mitiga el riesgo con el protocolo?"),
      answer: t("answer20"),
    },
    {
      id: 15,
      question: t("¿Por qué utilizamos Smart Contract?"),
      answer: t("answer21"),
    },
    {
      id: 16,
      question: t("¿Por qué utilizamos Blockchain y por qué la de BNB Chain?"),
      answer: t("answer22"),
    },
    {
      id: 17,
      question: t(
        "¿Qué pasaría si Binance deja de funcionar, esto afectaría la red de BNB?"
      ),
      answer: t("answer23"),
    },
  ];

  return (
    <>
      <div className="component-preguntasFrecuentes">
        <div className="container-preguntas-text">
          <h1>{t("Preguntas Frecuentes")}</h1>
        </div>
        <div className="container-list-question">
          <div className="accordion" id="accordionExample">
            {listQuestion.map((question, index) => {
              return (
                <div className="accordion-item" key={index}>
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={`#accordion${question.id}`}
                      aria-controls={`accordio${question.id}`}
                      aria-expanded="false"
                    >
                      {question.question}
                    </button>
                  </h2>
                  <div
                    id={`accordion${question.id}`}
                    className={`accordion-collapse collapse`}
                  >
                    <div className="accordion-body">{question.answer}</div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="component-preguntasFrecuentesMovil">
        <div className="container-preguntas-text">
          <h1>{t("Preguntas Frecuentes")}</h1>
        </div>
        <div className="container-list-question">
          {listQuestionMovil.map((question, index) => {
            return (
              <div className="container-preg-resp" key={index}>
                <h2>{question.question}</h2>
                <p>{question.answer}</p>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default PreguntasFrecuentes;
