import moment from "moment";

export function formatNumberWithTwoDecimals(number) {
  // Convertir el número a una cadena
  const numStr = number.toString();
  
  // Encontrar la posición del punto decimal
  const decimalIndex = numStr.indexOf('.');
  
  // Si no hay punto decimal, agregar ".00"
  if (decimalIndex === -1) {
    return numStr + ".00";
  }
  
  // Obtener la parte decimal
  const decimalPart = numStr.substring(decimalIndex + 1);
  
  // Si la parte decimal tiene menos de dos dígitos, agregar ceros
  if (decimalPart.length < 2) {
    return numStr + "0";
  }
  
  // Buscar el primer decimal diferente de cero
  let nonZeroIndex = decimalPart.length;
  for (let i = 0; i < decimalPart.length; i++) {
    if (decimalPart[i] !== '0') {
      nonZeroIndex = i;
      break;
    }
  }
  
  // Formatear el número con dos decimales y el resto de los dígitos no cero
  return `${numStr.substring(0, decimalIndex)}.${decimalPart.substring(0, Math.max(nonZeroIndex + 1, 2))}`;
}

export const formatDate = (creationTime)=>{
  // Creamos un objeto Moment utilizando Moment.js
  const fechaMoment = moment( Number(creationTime) * 1000);
  const fechaFormateadaInit = fechaMoment.format("D/M/YYYY");

  const fechaSumada = fechaMoment.add(360, 'days');
  // Formateamos la fecha en el formato deseado
  const fechaFormateadaFinal = fechaSumada.format("D/M/YYYY");

  const partesFechaInit = fechaFormateadaInit.split("/");
  const partesFechaEnd = fechaFormateadaFinal.split("/");
  // Formatear la fecha en el nuevo formato "yyyy-m-d"
  const fechaFormateInit = `${partesFechaInit[2]}-${partesFechaInit[1]}-${partesFechaInit[0]}`;
  const fechaFormateEnd = `${partesFechaEnd[2]}-${partesFechaEnd[1]}-${partesFechaEnd[0]}`;

  return [fechaFormateadaInit,fechaFormateadaFinal,fechaFormateInit,fechaFormateEnd];
}

export function calcularTiempoRestante(tiempoDeInicioEnSegundos, duracionEnSegundos) {
  const tiempoDeFinalizacionEnSegundos = tiempoDeInicioEnSegundos + duracionEnSegundos;
  const tiempoActualEnSegundos = Math.floor(Date.now() / 1000); // Convertir tiempo actual a segundos

  let tiempoRestanteEnSegundos = tiempoDeFinalizacionEnSegundos - tiempoActualEnSegundos;

  const segundosEnMinuto = 60;
  const segundosEnHora = segundosEnMinuto * 60;
  const segundosEnDia = segundosEnHora * 24;
  const segundosEnMes = segundosEnDia * 30; // Suponiendo meses de 30 días
  const segundosEnAno = segundosEnDia * 365; // Suponiendo años de 365 días

  const anosRestantes = Math.floor(tiempoRestanteEnSegundos / segundosEnAno);
  tiempoRestanteEnSegundos %= segundosEnAno;

  const mesesRestantes = Math.floor(tiempoRestanteEnSegundos / segundosEnMes);
  tiempoRestanteEnSegundos %= segundosEnMes;

  const diasRestantes = Math.floor(tiempoRestanteEnSegundos / segundosEnDia);
  tiempoRestanteEnSegundos %= segundosEnDia;

  const horasRestantes = Math.floor(tiempoRestanteEnSegundos / segundosEnHora);
  tiempoRestanteEnSegundos %= segundosEnHora;

  const minutosRestantes = Math.floor(tiempoRestanteEnSegundos / segundosEnMinuto);
  tiempoRestanteEnSegundos %= segundosEnMinuto;

  return {
    anos: anosRestantes,
    meses: mesesRestantes,
    dias: diasRestantes,
    horas: horasRestantes,
    minutos: minutosRestantes,
    segundos: tiempoRestanteEnSegundos
  };
}

export function calcularTiempoRestanteEnSegundos(tiempoDeInicioEnSegundos, duracionEnSegundos) {
  const tiempoDeFinalizacionEnSegundos = tiempoDeInicioEnSegundos + duracionEnSegundos;
  const tiempoActualEnSegundos = Math.floor(Date.now() / 1000); // Convertir tiempo actual a segundos

  const tiempoRestanteEnSegundos = tiempoDeFinalizacionEnSegundos - tiempoActualEnSegundos;

  return tiempoRestanteEnSegundos;
}

export function construirLinkReferral(url,address) {
  return `${url}/register?ref=${address}`
}

/**
 * Convierte un número en una cadena con formato monetario personalizado.
 *
 * @param {object} opciones - Opciones para el formato monetario.
 * @param {number} opciones.number - El número que se va a formatear.
 * @param {string} [opciones.firstSign=""] - El firstSigno de moneda o símbolo que se agregará.
 * @param {string} [opciones.lastSign=""] - El lastSigno de moneda o símbolo que se agregará.
 * @param {string} [opciones.thousandSeparator=","] - El separador de miles.
 * @param {string} [opciones.decimalSeparator="."] - El separador decimal.
 * @param {number} [opciones.numberOfDecimals=2] - El número de decimales a mostrar.
 * @returns {string} La cadena formateada con el número en formato monetario.
 */
export function NumberToMoney({ number, firstSign = "",lastSign="", thousandSeparator = ".", decimalSeparator = ",", numberOfDecimals = 2 }) {
  // Convertir el número a una cadena y ajustar los decimales
  var numeroFormateado = number.toString();

  // Separar parte entera y decimal
  var partes = numeroFormateado.split(".");

  // Formatear parte entera con separador de miles
  var parteEntera = partes[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousandSeparator);

  // Rellenar parte decimal con ceros si es necesario
  var parteDecimal = numberOfDecimals === 0 ? "" : `${decimalSeparator}${partes[1] ? partes[1].substring(0, numberOfDecimals) : "0".repeat(numberOfDecimals)}`;

  // Construir la cadena formateada final
  return `${firstSign}${parteEntera}${parteDecimal}${lastSign}`;
}
