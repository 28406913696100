import Footer from "./Footer";
import { Route, Routes } from "react-router-dom";
import Home from "../pages/home";
import Navbar from "./Navbar";
import PreguntasFrecuentes from "../pages/preguntasFrecuentes";
import TerminosCondiciones from "../pages/terminosCondiciones";

const Layout = () => {
  return (
    <div className="layout">
      <div>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route
            path="/preguntasFrecuentes"
            element={<PreguntasFrecuentes />}
          />
          <Route
            path="/terminosYCondiciones"
            element={<TerminosCondiciones />}
          />

          <Route
            path="*"
            element={
              <h2 className="text-center" style={{ color: "#001848" }}>
                Page not found
              </h2>
            }
          />
        </Routes>
      </div>

      <div>
        <Footer />
      </div>
    </div>
  );
};

export default Layout;
