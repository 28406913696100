import React, { useState } from "react";
import delfin from "../assets/gif/Message.gif";
import { useTranslation } from "react-i18next";
import Modal from "react-modal";
import delfinModal from "../assets/imgs/delfinMessage.png";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

Modal.setAppElement("#root");

const Registrate = ({ isRegister, setIsRegister }) => {
  const { t } = useTranslation();

  const [phone, setPhone] = useState("");
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [error, setError] = useState();

  const [modalIsOpenPlan, setModalIsOpenPlan] = useState(false);
  const handlerModalClosePlan = () => setModalIsOpenPlan(false);
  const handlerModalOpenPlan = () => {
    setModalIsOpenPlan(true);
    console.log(phone, fullName, email);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!fullName) {
      setError(t("El nombre es obligatorio"));
      return;
    } else if (fullName.length < 3) {
      setError(t("El nombre debe tener al menos 3 caracteres"));
      return;
    } else if (/\d|\W/.test(fullName)) {
      setError(t("El nombre no debe contener números"));
      return;
    }

    if (!email) {
      setError(t("El correo es obligatorio"));
      return;
    } else if (!/[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}/.test(email)) {
      setError(t("El correo no tiene un formato válido"));
      return;
    }

    if (!phone) {
      setError(t("El número es obligatorio"));
      return;
    }

    // incio aqui va la peticion  a la API

    const newUser = {
      email,
      fullName,
      phone,
    };

    setTimeout(() => {
      setIsRegister(true);
    }, 3000);

    // fin aqui va la peticion  a la API

    // si el usurio ingresa toda la informacion bien elimina el mensaje de error y muestra el modal
    handlerModalOpenPlan();
    setError("");
  };

  return (
    <div className="component-registrate">
      <div className="container-title">
        <h1 className="title">
          {t("Recibe las últimas actualizaciones de")}
          <span className="title-blue"> IA Money</span>
        </h1>
      </div>
      <div className="container-img-register">
        <div className="container-img">
          <img className="img-delfin" src={delfin} alt="" />
        </div>
        <form className="container-form" onSubmit={handleSubmit}>
          <p className="text-registrate">{t("Regístrate acá")}:</p>
          <div className="container-name container-global">
            <label htmlFor="name">{t("Nombre completo")}</label>
            <input
              type="text"
              id="name"
              placeholder={t("Nombre completo")}
              onChange={(e) => setFullName(e.target.value)}
            />
          </div>
          <div className="container-email container-global">
            <label>{t("e-mail")}</label>
            <input
              type="email"
              placeholder="Example@domain.com"
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="container-number container-global">
            <PhoneInput
              defaultCountry="MX"
              placeholder="Ej: 5585264448"
              value={phone}
              onChange={setPhone}
            />
            {error && <div className="errorForm">{error}</div>}
          </div>
          <div className="container-button">
            <button className="button-blue" type="submit">
              {t("Registrarme")}
            </button>
          </div>
          <Modal
            isOpen={modalIsOpenPlan}
            onRequestClose={handlerModalClosePlan}
            contentLabel="Example Modal"
            className="container-registrar"
          >
            <div className="modal-registar-form">
              <div className="container-img">
                <img src={delfinModal} alt="" />
              </div>
              <h6 className="title-modal">{t("Gracias por registrarte")}</h6>
              <p className="text">{t("Texto Modal")}</p>
              <div className="container-button">
                <button className="button-blue">
                  {t("Me gustaría más información")}
                </button>
              </div>
            </div>
          </Modal>
        </form>
      </div>
    </div>
  );
};

export default Registrate;
