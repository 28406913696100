import React, { useState } from "react";
import ComoFunciona from "../components/ComoFunciona";
import EligePlan from "../components/EligePlan";

// import QuieresSaberMas from "../components/QuieresSaberMas";
import Inscribete from "../components/Inscribete";
import Registrate from "../components/Registrate";
import DeseasSaberMas from "../components/DeseasSaberMas";
import Preguntas from "../components/Preguntas";
import Carousel from "../components/Carousel";
import QueHacemos from "../components/QueHacemos";

const Home = () => {
  const [isRegister, setIsRegister] = useState(false);

  return (
    <div className="page-home">
      <Carousel />
      <QueHacemos />
      <ComoFunciona />
      <EligePlan />

      {/* <QuieresSaberMas /> */}
      <Inscribete />
      {isRegister === false ? (
        <Registrate isRegister={isRegister} setIsRegister={setIsRegister} />
      ) : (
        <DeseasSaberMas />
      )}
      <Preguntas />
    </div>
  );
};

export default Home;
