import React from "react";

const BarraCarousel = ({ list, selectImg, toogleImgBanner, styleCss }) => {
  return (
    <div className={styleCss}>
      {list.map((img) => (
        <div
          key={img.id}
          className="barraP"
          onClick={() => toogleImgBanner("barra", img.id)}
        >
          <div
            className={`${
              selectImg === img.id ? "barraH --bgBarra " : "barraH"
            }`}
          ></div>
        </div>
      ))}
    </div>
  );
};

export default BarraCarousel;
